<template>
	<div>
		<v-text-field
			single-line
			outlined
			dense
			autocomplete="false"
			hide-details
			height="48"
			color="#CACEDA"
			required
			:readonly="$store.state.setting.distributor.role === 'AU'"
			type="text"
			@keypress="onlyForCurrency"
			v-model="displayValue"
			@blur="isInputActive = false"
			:rules="priceRules"
			@focus="isInputActive = true"
		></v-text-field>
	</div>
</template>

<script>
export default {
	props: ["value"],

	data() {
		return {
			isInputActive: false,
			priceRules: [
				v => !!v || "Price is required",
				v => /^[^0]/.test(v) || "not zero"
			]
		};
	},

	computed: {
		displayValue: {
			get() {
				if (this.isInputActive) {
					// Cursor is inside the input field. unformat display value for user
					return this.value.toString();
				} else {
					if (this.value === 0) {
						return 0;
					} else {
						return (
							"$" +
							this.value
								.toFixed(2)
								.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
						);
					}
				}
			},
			set(modifiedValue) {
				// Recalculate value after ignoring "$" and "," in user input
				let newValue = parseFloat(
					modifiedValue.replace(/[^\d\.]/g, "")
				);

				// Ensure that it is not NaN
				if (isNaN(newValue)) {
					newValue = 0;
				}
				// Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
				// $emit the event so that parent component gets it
				this.$emit("input", newValue);
			}
		}
	},

	methods: {
		onlyForCurrency($event) {
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;
			// only allow number and one dot
			if (
				(keyCode < 48 || keyCode > 57) &&
				(keyCode !== 46 || this.value.toString().indexOf(".") != -1)
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (
				this.value != null &&
				this.value.toString().indexOf(".") > -1 &&
				this.value
					.toString()
					.substring(this.value.toString().indexOf(".")).length > 2
			) {
				$event.preventDefault();
			}
		}
	}
};
</script>

